import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useDeferredValue, useEffect, useRef, useState } from "react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdOutlineSearch,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  fetchAttachmentById,
  inboxDetails,
  listInbox,
  setIsRead,
  updateTrack,
} from "../../apis/inbox";
// import InputField from "../../components/FormElements/components/InputField";
import Input from "../../components/Input/Input";
import "./inbox.css";
import EmailDetailCard from "./inboxDetail";
import { Add, AttachFile } from "@mui/icons-material";
import AllAttachment from "./Attachments/AllAttachment";
import FilterChip from "./FilterChip";
import AddFilters from "./AddFilters";
import { inboxTrackHistory } from "../../apis/inbox";
import useTranslation from "../../hooks/useTranslation";
import { deleteFilter, getUserFilter } from "../../apis/flowBuilder";
import { Icon } from "@iconify/react";
import { ResizableBox } from "react-resizable";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { filter } from "lodash";
import toast from "react-hot-toast";

const filterInitialValue = {
  startDate: "",
  endDate: "",
};

export function Inbox(category: any) {
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [inboxList, setinboxList] = useState<any>([]);
  const [selectedInboxDetails, setSelectedInboxDetails] = useState<any>({});
  const [dialog, setDialog] = useState(false);
  const [dialogItem, setDialogItem] = useState<any>(null);
  const [actionId, setActionId] = useState<any>(null);
  const location = useLocation();
  const [allAttachmentDrawer, setAllAttachmentDrawer] = useState(false);
  const [createFilerOpen, setCreateFilterOpen] = useState(false);
  const { translate } = useTranslation();
  const [selectedTrackHistory, setSelectedTrackHistory] = useState<any>([]);
  const [filterList, setFilterList] = useState([]);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [inboxWidth, setInboxWidth] = useState<any>(400);

  const [loading, setLoading] = useState(true);

  console.log(selectedTrackHistory);

  useEffect(() => {
    setInboxWidth(Number(localStorage.getItem("inbox-width")) || 400);
  }, []);

  console.log(inboxList);
  const [searchValue, setSearchValue] = useState("");

  const handleClear = () => {
    setSearchValue("");
  };

  const [filterChipSelected, setFilterChipSelected] = useState<
    { name: string; color: string; is_default?: boolean } | undefined
  >(undefined);

  const scrollRef: any = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current?.scrollBy({ left: -280, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current?.scrollBy({ left: 280, behavior: "smooth" });
    }
  };

  const handleClose = () => {
    setDialog(false);
  };
  const handleTriggerAction = async () => {
    const formData: any = {};

    // Select all the form fields inside the specific div (including file inputs)
    const formFields = document.querySelectorAll(
      ".px-5 input, .px-5 select, .px-5 textarea"
    );

    formFields.forEach((inputField: any) => {
      // const fieldLabel = inputField.previousElementSibling?.innerText; // Assuming label is the previous sibling
      const fieldName = inputField.name; // Assuming label is the previous sibling
      if (fieldName) {
        // Handle file inputs separately
        if (inputField.type === "file" && inputField.files.length > 0) {
          formData[fieldName] = inputField.files[0]; // Capture the first file selected
        } else {
          formData[fieldName] = inputField.value; // Capture text value for other fields
        }
      }
    });

    try {
      formData["uuid"] = actionId;
      console.log("selectedInboxDetails: ", selectedInboxDetails);
      formData["in_uuid"] = selectedInboxDetails.in_uuid;

      // Assuming updateTrack will handle multipart/form-data
      const result = await updateTrack(formData, formData); // Pass both formData and file data

      console.log("Process tracked successfully:", result);
      handleClose(); // Close the dialog after API call
    } catch (error) {
      console.error("Failed to track process:", error);
    }
  };

  useEffect(() => {
    updateScrollButtons();
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", updateScrollButtons);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, [filterList]);

  useEffect(() => {
    if (location.state?.display) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
    getInboxList();
  }, [category.category, searchValue, selectedFilter]);

  useEffect(() => {
    setSelectedInboxDetails({});
    setSelectedTrackHistory([]);
  }, [category.category]);

  useEffect(() => {
    getUserFilterList();
  }, []);

  async function getUserFilterList() {
    try {
      const data = await getUserFilter();
      console.log(data);
      setFilterList(data ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  const getInboxList = async (filter?: any) => {
    try {
      const data = await listInbox(
        category.category,
        searchValue,
        selectedFilter === "allmails" ? "" : selectedFilter,
        filter?.startDate ?? "",
        filter?.endDate ?? ""
      );
      setinboxList(data);
      setLoading(false);
      if (category.category === "inprogress") {
        getInboxDetails(data[0].uuid);
      }
      getInboxTrackHistory(data[0].uuid);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getInboxDetails = async (id: any) => {
    try {
      const data = await inboxDetails(id);
      setSelectedInboxDetails(data);
      // setCommonData(data);
      // setHistoricData(data.history);
      // console.log("CommonData: ", commonData);
      // console.log("HistoricData: ", historicData);
      console.log("selectedInboxDetails: ", selectedInboxDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const updateScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const getInboxTrackHistory = async (id: any) => {
    try {
      const data = await inboxTrackHistory(id);
      setSelectedTrackHistory(data);
      console.log(data);
      // setCommonData(data);
      // setHistoricData(data.history);
      // console.log("CommonData: ", commonData);
      // console.log("HistoricData: ", historicData);
      console.log("selectedInboxDetails: ", selectedInboxDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = (item: any) => {
    setDialogItem(item); // Set the current action item in dialog
    setActionId(item.uuid);
    setDialog(true);
  };

  async function deleteChip(chip: any) {
    try {
      const data = await deleteFilter(chip.uuid);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      getUserFilterList();
    }
  }

  function editChip(chip: any) {
    setFilterChipSelected({ ...chip, colour: chip.color });
    setCreateFilterOpen(true);
  }

  const [filters, setFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterApplied, setIsFilteredApplied] = useState(false);
  const dropdownRef = useRef(null);

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const ApplyFilter = () => {
    setIsOpen(false);
    setIsFilteredApplied(true);
    if (filters.startDate || filters.endDate) {
      getInboxList(filters);
    }
  };

  const [attachmentData, setAttachmentData] = useState([]);
  console.log(attachmentData);
  const onClickAttachment = async (Id: string) => {
    try {
      const res = await fetchAttachmentById(Id);
      setAllAttachmentDrawer(true);
      setAttachmentData(res);
    } catch (error: any) {
      toast.error(error?.message ?? "");
    }
  };

  const postIsRead = async (trackId: any) => {
    try {
      const res = await setIsRead(trackId);
      getInboxList();
    } catch (error) {
      console.log(error);
    }
  };

  if (allAttachmentDrawer) {
    return (
      <>
        <AllAttachment
          open={allAttachmentDrawer}
          onClose={() => setAllAttachmentDrawer(false)}
          data={attachmentData}
        />
      </>
    );
  }

  console.log(selectedTrackHistory)

  return (
    <div className="flex flex-row h-full">
      {createFilerOpen && (
        <AddFilters
          open={createFilerOpen}
          handleClose={() => {
            setFilterChipSelected(undefined);
            setCreateFilterOpen(false);
          }}
          onCreateSuccess={getUserFilterList}
          value={filterChipSelected}
        />
      )}
      <ResizableBox
        width={inboxWidth}
        height={100}
        axis="x"
        resizeHandles={["e"]}
        onResizeStop={(_, { size }) => {
          localStorage.setItem("inbox-width", String(size.width));
          setInboxWidth(size.width);
        }}
        minConstraints={[300, 0]}
        maxConstraints={[700, 0]}
        handle={
          <div
            style={{
              width: "2px",
              cursor: "ew-resize",
              backgroundColor: "#e5e7eb",
              height: "100vh",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        }
      >
        <div className={`border-r  border-gray-200 min-w-[${inboxWidth}px]`}>
          {loading ? (
            <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="border-b pb-2">
                <div className="p-3 pr-1 pb-2 gap-1 flex flex-row items-center">
                  <div className="relative w-full max-w-md">
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                      <AiOutlineSearch size={20} />
                    </div>

                    <input
                      type="text"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder={translate("placeholderSearch")}
                      className="w-full h-10 pl-10 pr-10 rounded-lg border border-gray-300 
                 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 
                 focus:border-transparent placeholder-gray-400"
                    />

                    {searchValue && (
                      <button
                        onClick={handleClear}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                   hover:text-gray-600 focus:outline-none"
                      >
                        <MdClose size={20} />
                      </button>
                    )}
                  </div>

                  <div className="relative inline-block">
                    {/* Filter Icon */}

                    <button
                      onClick={toggleDropdown}
                      className="flex items-center gap-2 px-3 py-3 text-white rounded-lg bg-primary shadow-xs hover:bg-blue-700"
                    >
                      <BsFilter className="w-4 h-4" />
                    </button>
                    {isOpen && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-0 mt-2 p-4 bg-white border border-gray-300 rounded-md shadow-lg z-[1000]"
                      >
                        <h4 className="mb-2">
                          <FormattedMessage id="filtersText" />
                        </h4>
                        <div className="flex-1 w-full">
                          <label className="block text-sm font-medium mb-1 text-gray-600">
                            <FormattedMessage id="filterDateRange"></FormattedMessage>
                          </label>
                          <div className="flex gap-2 items-center">
                            <input
                              type="date"
                              value={filters.startDate}
                              onChange={(e) =>
                                handleFilterChange("startDate", e.target.value)
                              }
                              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <span>
                              <FormattedMessage id="filterTo"></FormattedMessage>
                            </span>

                            <input
                              type="date"
                              value={filters.endDate}
                              onChange={(e) =>
                                handleFilterChange("endDate", e.target.value)
                              }
                              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </div>
                        </div>
                        <div className="flex justify-between mt-4">
                          <button
                            onClick={() => {
                              setFilter(filterInitialValue);
                            }}
                            className="px-3 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300"
                          >
                            <FormattedMessage id="filterReset" />
                          </button>
                          <button
                            onClick={ApplyFilter}
                            className="px-3 py-1 text-sm bg-primary text-white rounded-md hover:bg-blue-700"
                          >
                            <FormattedMessage id="filterApply" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <IconButton onClick={() => setCreateFilterOpen(true)}>
                    <Add />
                  </IconButton>
                </div>
                {isFilterApplied &&
                  (filters?.startDate || filters?.endDate) && (
                    <div className="ml-3 flex items-center justify-start gap-2">
                      <div className="inline-flex items-center px-3 py-1 bg-gray-50 rounded-lg shadow-sm border border-gray-200">
                        <div className="flex items-center space-x-2 text-xs font-medium text-gray-700">
                          {filters?.startDate && (
                            <span className="flex items-center">
                              <span className="text-gray-500">
                                <FormattedMessage id="from" />:
                              </span>
                              <span className="ml-1 text-gray-900">
                                {new Date(
                                  filters?.startDate
                                ).toLocaleDateString()}
                              </span>
                            </span>
                          )}
                          {filters?.startDate && filters?.endDate && (
                            <span className="text-gray-400 px-2">•</span>
                          )}
                          {filters?.endDate && (
                            <span className="flex items-center">
                              <span className="text-gray-500">
                                <FormattedMessage id="to" />:
                              </span>
                              <span className="ml-1 text-gray-900">
                                {new Date(
                                  filters?.endDate
                                ).toLocaleDateString()}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setFilter(filterInitialValue);
                          setIsFilteredApplied(false);
                          getInboxList(filterInitialValue);
                        }}
                        className="flex items-center gap-1 px-3 py-1 text-xs bg-primary rounded-lg shadow-sm text-white rounded-md hover:bg-blue-700"
                      >
                        <MdClose></MdClose>
                        <FormattedMessage id="clearFilter" />
                      </button>
                      {/* <button className="flex flex-row items-center px-3 py-1 bg-gray-50 rounded-lg shadow-sm border border-gray-200 text-xs font-medium text-gray-700">
                    Clear filters
                  </button> */}
                    </div>
                  )}
              </div>

              <Stack
                alignItems="center"
                direction="row"
                paddingY={1}
                spacing={1}
              >
                <IconButton
                  onClick={scrollLeft}
                  className="hover:bg-gray-300 p-1 rounded-md"
                  disabled={!canScrollLeft}
                  size="small"
                >
                  <MdChevronLeft size={25} />
                </IconButton>

                <Stack
                  direction="row"
                  spacing={1.5}
                  flex={1}
                  overflow="hidden"
                  ref={scrollRef}
                >
                  <FilterChip
                    name={"All Mails"}
                    color={"blue"}
                    selected={"allmails" === selectedFilter}
                    onClick={() => setSelectedFilter("allmails")}
                    chipData={{
                      uuid: "allmails",
                      color: "blue",
                      name: "All Mails",
                    }}
                  />
                  {filterList &&
                    Array.isArray(filterList) &&
                    filterList?.map((chip: any) => {
                      return (
                        <FilterChip
                          name={chip.name}
                          color={chip.color}
                          key={chip.uuid}
                          selected={chip.uuid === selectedFilter}
                          onClick={() => setSelectedFilter(chip.uuid)}
                          onDelete={deleteChip}
                          onEdit={editChip}
                          chipData={chip}
                        />
                      );
                    })}
                </Stack>

                <IconButton
                  onClick={scrollRight}
                  className="hover:bg-gray-300 p-1 rounded-md"
                  disabled={!canScrollRight}
                  size="small"
                >
                  <MdChevronRight size={25} />
                </IconButton>
              </Stack>
              <div className="inbox-container">
                {Array.isArray(inboxList) &&
                  inboxList?.map((section: any, index: any) => {
                    const isUnreadStyle =
                      activeSectionIndex !== index &&
                      section.is_read &&
                      category.category === "inprogress";

                    return (
                      <div
                        key={section?.uuid}
                        className={`inbox-item ${
                          activeSectionIndex === index ? "active" : ""
                        }`}
                        style={{
                          opacity: isUnreadStyle ? 0.7 : 1,
                        }}
                        onClick={() => {
                          setIsShow(true);
                          setActiveSectionIndex(index);
                          if (category.category === "inprogress") {
                            getInboxDetails(section?.uuid);
                            postIsRead(section?.uuid);
                          }
                          getInboxTrackHistory(section?.uuid);
                          setSelected(section);
                        }}
                      >
                        <div className="inbox-item-content flex gap-3">
                          {/* <img src="/avatar.svg" alt="Avatar" className="inbox-item-image" /> */}
                          <Avatar>{section?.node_name.split("")[0]}</Avatar>
                          <div className="inbox-item-details">
                            <span className="inbox-item-label">
                              <Typography
                                variant="h5"
                                fontWeight={isUnreadStyle ? "normal" : "bold"}
                              >
                                {section?.node_name}
                              </Typography>
                            </span>
                            {/* <span className="inbox-item-label">{section?.process_name}</span> */}
                            <span className="inbox-item-time flex items-center gap-1">
                              <Icon icon="uil:process" /> {section.process_name}{" "}
                              | {section?.request_id}
                            </span>
                            <span className="inbox-item-date flex items-center gap-1">
                              <Icon icon="solar:calendar-linear" />{" "}
                              {section?.formatted_created_at} - {section?.created_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </ResizableBox>
      <div className="w-full">
        <div className="w-full border-b p-5">
          {selected && (
            <div className="flex justify-between items-center">
              <div>
                <div className="px-2 overflow-hidden justify-center">
                  {/* <img
                    src={"https://i.pravatar.cc/300"}
                    width={35}
                    height={35}
                    alt="icon"
                    className="rounded-full"
                    /> */}
                </div>
                <div>
                  <Typography variant="h5">{selected?.process_name}</Typography>
                  <Typography variant="caption">
                    <FormattedMessage id="requestId"></FormattedMessage>:{" "}
                    {`${selected?.request_id}`}
                  </Typography>
                </div>
              </div>
              {/* <Button
                variant="text"
                sx={{ textTransform: "none" }}
                startIcon={<AttachFile sx={{ transform: "rotate(45deg)" }} />}
                onClick={() => setAllAttachmentDrawer(true)}
              >
                All Attachments
              </Button> */}
            </div>
          )}
        </div>
        {/* All Attachments Drawer */}
        {/* <AllAttachment
          open={allAttachmentDrawer}
          onClose={() => setAllAttachmentDrawer(false)}
        /> */}

        <div
          className=""
          style={{
            height: "calc(100vh - 148px)",
            overflowY: "auto",
          }}
        >
          {category.category === "inprogress" && selectedInboxDetails.uuid && (
            // Only render EmailDetailCard if selectedInboxDetails is an array
            // Array.isArray(selectedInboxDetails) && selectedInboxDetails.map((item: any) => (
            <EmailDetailCard
              category={category.category}
              // key={item?.uuid}
              selectedInboxDetails={selectedInboxDetails}
              selected={selected}
              handleAction={handleAction}
              refreshInbox={() => {
                setSelectedInboxDetails({});
                getInboxList();
              }}
              onClickAttachment={() =>
                onClickAttachment(selectedInboxDetails.uuid)
              }
            />
          )}
          {selectedTrackHistory?.map((trackHistory: any) => (
            <EmailDetailCard
              category={category.category}
              // key={item?.uuid}
              selectedInboxDetails={trackHistory}
              selected={selected}
              handleAction={handleAction}
              readOnly={true}
              onClickAttachment={() => onClickAttachment(trackHistory.uuid)}
            />
          ))}
        </div>
        {/* {selected && isShow &&(
            <EmailDetailCard
              selectedInboxDetails={selectedInboxDetails}
              // commonData={commonData}
              // historicData={historicData}
              selected={selected}
              handleAction={handleAction}
            />
          )} */}
      </div>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Conditionally render the message based on dialogItem.completed */}
            {dialogItem && !dialogItem.completed
              ? "Are you sure you want to trigger with this action?"
              : "This action is already triggered."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* Render Confirm button only if dialogItem.completed is false */}
          {dialogItem && !dialogItem.completed && (
            <Button onClick={handleTriggerAction}>Confirm</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
