import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import Dropdown from "../Dropdown/Dropdown";
import CheckBox from "../FormElements/components/CheckBox";
import DatePickerCustom from "../FormElements/components/DatePicker";
import FileUpload from "../FormElements/components/FileUpload";
import InputField from "../FormElements/components/InputField";
import MultiFileUpload from "../FormElements/components/MultiFileUpload";
import MultiSelectField from "../FormElements/components/MultiSelectDropdown";
import { elements_type } from "../FormElements/constants";
// @ts-ignore
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import SignatureCanvas from "react-signature-canvas";
import {
  fetchColumnValues,
  fetchColumnValuesByParent,
} from "../../apis/flowBuilder";
import DynamicFormPopup from "../../components/Modal/DynamicForm";
import OpenLayersMap from "../../containers/map/OpenLayersMap";
import useTranslation from "../../hooks/useTranslation";
import DirectionBasedOnLanguage from "../DirectionBasedOnLanguage";
import DateTimePickerCustom from "../FormElements/components/DateTimePicker";
import { activeLanguageData } from "../FormElements/formTranslations";

interface FormPreviewProps {
  nodeId: string;
  formFields: Array<any>;
  formContainerWidth?: number;
  activeLanguage?: string;
}
export default function FormPreview({
  nodeId,
  formFields,
  formContainerWidth,
  activeLanguage,
}: FormPreviewProps) {
  console.log(formFields);
  const { locale } = useIntl();
  const { translate } = useTranslation(activeLanguage || locale);

  const [previewOptions, setPreviewOptions] = useState<
    Record<number, Array<any>>
  >({});

  console.log("form preiview", formFields);

  return (
    <DirectionBasedOnLanguage language={activeLanguage || locale}>
      {/* <LangTab
        activeLanguage={activeLanguage}
        setActiveLanguage={setActiveLanguage}
      /> */}
      <div className="p-4">
        <DynamicFormPopup
          formData={formFields}
          onSubmit={() => {}}
          isPreview={true}
          actionList={[]}
          previewActiveLang={activeLanguage}
        />
      </div>
      {/* <div className="flex flex-col items-start justify-center gap-3 p-4">
        <>
          {formFields.length > 0 ? (
            <Grid container spacing={1}>
              {formFields.map((field, index) => {
                return (
                  <PreviewElements
                    previewOptions={previewOptions}
                    setPreviewOptions={setPreviewOptions}
                    formFields={formFields}
                    field={field}
                    index={index}
                    formContainerWidth={formContainerWidth}
                    activeLanguage={activeLanguage || locale}
                  />
                );
              })}
            </Grid>
          ) : (
            <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "20px",
                  color: "#555",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  margin: "20px 0",
                }}
              >
                <InfoIcon
                  style={{
                    fontSize: 50,
                    marginBottom: "10px",
                    color: "#90caf9",
                  }}
                />
                <Typography
                  variant="h6"
                  textAlign="center"
                  color="textSecondary"
                >
                  <FormattedMessage id="noFieldsFormYet"></FormattedMessage>
                </Typography>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="textSecondary"
                >
                  <FormattedMessage id="startAddingFields"></FormattedMessage>
                </Typography>
              </div>
            </div>
          )}
          {formFields.length > 0 && (
            <Button
              variant="contained"
              disableElevation
              type="submit"
              onClick={() => {}}
            >
              {translate("submitButton")}
            </Button>
          )}
        </>
      </div> */}
    </DirectionBasedOnLanguage>
  );
}

export const PreviewElements = ({
  formFields,
  field,
  index,
  formContainerWidth,
  activeLanguage: locale = "en",
  previewOptions,
  setPreviewOptions,
}: {
  formFields?: any;
  field: any;
  index: number;
  formContainerWidth?: number;
  activeLanguage?: string;
  previewOptions?: any;
  setPreviewOptions?: any;
}) => {
  const [cascadingValues, setCascadingValues] = useState<
    Record<number, string>
  >({});

  const fieldWidth = field?.width || 100;
  function removePercentage(percentage: number): number {
    const result = 12 * (1 - percentage / 100); // Adjusted formula
    return 12 - result;
  }

  const pixelValue = (percentage: number = 0) => {
    const widthPercentage = percentage ? percentage : 100;
    return (widthPercentage / 100) * ((formContainerWidth ?? 0) - 160);
  };

  const fieldContainerStyle = {
    marginBottom: "20px", // Add margin to separate form fields
  };

  function getActiveLanguageData(key: string) {
    return activeLanguageData(field, locale, key);
  }

  const GetColumnValues = async () => {
    try {
      const res = await fetchColumnValues(field.dropdownDetails.tableId);
      setPreviewOptions((prev: any) => ({ ...prev, [field.id]: res }));
    } catch (error) {}
  };

  useEffect(() => {
    if (field?.dropdownDetails?.tableId && !field?.dropdownDetails?.parentId) {
      GetColumnValues();
    }
  }, []);

  const handleDropdownChange = (value: string, i: number, field: any) => {
    let isChildElement = formFields.find(
      (item: any) => field.id === item.dropdownDetails.parentId
    );

    if (isChildElement?.id) {
      GetColumnValuesByParent(
        isChildElement?.dropdownDetails?.tableId,
        value,
        isChildElement?.id
      );
    }
    setCascadingValues((prev) => ({ ...prev, [field.id]: value }));
  };

  const GetColumnValuesByParent = async (
    tableId: string,
    value: string,
    id: string
  ) => {
    try {
      const res = await fetchColumnValuesByParent(tableId, value);
      console.log(res);
      setPreviewOptions((prev: any) => ({ ...prev, [id]: res }));
    } catch (error) {}
  };

  const cascadingOptions = useMemo(
    () =>
      previewOptions?.[field?.id]?.map((x: any) => ({
        label: x?.value,
        value: x?.id,
      })),
    [previewOptions]
  );

  return (
    <Grid
      item
      key={index}
      md={
        // field?.element_type === elements_type.TITLE
        //   ? 12
        // :
        removePercentage(fieldWidth)
      }
    >
      {(() => {
        switch (field?.element_type) {
          case elements_type.TITLE:
            return (
              <Typography
                textAlign="center"
                variant="h3"
                sx={{
                  color: "#000",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                {field?.value || ""}
              </Typography>
            );
          case elements_type.TEXTFIELD:
            return (
              <InputField
                type={field?.input_type !== "string" ? "number" : undefined}
                step={field?.input_type === "float" ? "0.01" : undefined}
                label={getActiveLanguageData("label")}
                placeHolder={getActiveLanguageData("placeHolder")}
                onChange={() => {}}
                id=""
                name={field?.name}
              />
            );
          case elements_type.CHECKBOX:
            return (
              <Box>
                <CheckBox
                  label={
                    getActiveLanguageData("label") +
                    (field.required ? " *" : "")
                  }
                  onChange={() => {}}
                />
              </Box>
            );
          case elements_type.DROPDOWN:
            return (
              <Dropdown
                label={
                  getActiveLanguageData("label") + (field.required ? " *" : "")
                }
                options={
                  field?.translate?.[locale].options?.map((x: any) => ({
                    label: typeof x === "object" ? x.label : x,
                    value: typeof x === "object" ? x.label : x,
                  })) || []
                }
                name=""
                onChange={() => {}}
              />
            );
          case elements_type.RADIOBUTTON:
            return (
              <FormControl style={fieldContainerStyle}>
                <Typography variant="subtitle1" textTransform="capitalize">
                  {getActiveLanguageData("label") +
                    (field.required ? " *" : "")}
                </Typography>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                  {field?.translate[locale]?.options?.map((element: any) => (
                    <FormControlLabel
                      key={element.value}
                      value={element.value}
                      control={<Radio />}
                      label={element.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            );
          case elements_type.DATE:
            return (
              <DatePickerCustom
                label={
                  (getActiveLanguageData("label") || "Label") +
                  (field?.required ? " *" : "")
                }
                value=""
                name=""
                onChange={() => {}}
              />
            );
          case elements_type.DATE_TIME:
            return (
              <DateTimePickerCustom
                label={
                  getActiveLanguageData("label") + (field?.required ? " *" : "")
                }
                value={""}
                name={""}
                onChange={() => {}}
              />
            );
          case elements_type.TOGGLE:
            return (
              <FormControlLabel
                label={
                  <Typography variant="subtitle1" textTransform="capitalize">
                    {getActiveLanguageData("label") + " "}
                  </Typography>
                }
                control={<Switch defaultChecked={field.defaultChecked} />}
                required={field.required}
                style={{ fontWeight: "500", color: "black" }}
              />
            );
          case elements_type.FILEUPLOAD:
            return (
              <FileUpload
                label={
                  getActiveLanguageData("label") + (field.required ? " *" : "")
                }
                value={null}
                name=""
                onChange={() => {}}
                disabled={true}
              />
            );
          case elements_type.MULTIFILEUPLOAD:
            return (
              <MultiFileUpload
                label={
                  (getActiveLanguageData("label") || "Label") +
                  (field.required ? " *" : "")
                }
                name=""
                onChange={() => {}}
                disabled={true}
              />
            );
          case elements_type.MULTISELECTDROPDOWN:
            return (
              <MultiSelectField
                label={
                  (getActiveLanguageData("label") || "Label") +
                  (field.required ? " *" : "")
                }
                options={
                  field?.translate[locale].options?.map((x: any) => ({
                    label: typeof x === "object" ? x.label : x,
                    value: typeof x === "object" ? x.label : x,
                  })) || []
                }
                onChange={() => {}}
                name=""
              />
            );

          case elements_type.DIGITASIGNATURE:
            return (
              <div>
                {getActiveLanguageData("label") && (
                  <Typography
                    variant="subtitle1"
                    textTransform={"capitalize"}
                    mb={1}
                  >
                    {getActiveLanguageData("label")}{" "}
                  </Typography>
                )}
                <div
                  style={{
                    backgroundColor: "white",
                    width: pixelValue(field.width),
                  }}
                >
                  <SignatureCanvas
                    penColor="green"
                    canvasProps={{
                      width: pixelValue(field.width),
                      height: 200,
                      className: "sigCanvas",
                    }}
                  />
                </div>
              </div>
            );

          case elements_type.CASCADINGDROPDOWN:
            return (
              <div>
                <Dropdown
                  label={
                    getActiveLanguageData("label") +
                    (field.required ? " *" : "")
                  }
                  options={cascadingOptions}
                  name=""
                  onChange={(e) =>
                    handleDropdownChange(e.target.value, index, field)
                  }
                />
              </div>
            );
          case elements_type.GROUPFIELDS:
            return (
              <div
                style={{
                  border: "1px solid #dfdfdf",
                  padding: 12,
                  borderRadius: "10px",
                }}
              >
                {/* {JSON.stringify(field.fields)} */}
                <Typography
                  textAlign="center"
                  variant="h3"
                  sx={{
                    fontSize: "18px",
                    color: "#000",
                    marginBottom: "16px",
                    width: "100%",
                  }}
                >
                  {field?.title || ""}
                </Typography>
                {field?.fields?.length > 0 ? (
                  <DynamicFormPopup
                    formData={field?.fields}
                    onSubmit={() => {}}
                    isPreview={true}
                    actionList={[]}
                    // previewActiveLang={activeLanguage}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "20px",
                      color: "#555",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      margin: "20px 0",
                    }}
                  >
                    <InfoIcon
                      style={{
                        fontSize: 50,
                        marginBottom: "10px",
                        color: "#90caf9",
                      }}
                    />
                    <Typography
                      variant="h6"
                      textAlign="center"
                      color="textSecondary"
                    >
                      No fields have been added to this form group.
                    </Typography>
                  </div>
                )}
              </div>
            );
          case elements_type.LOCATION:
            return (
              <Paper sx={{ padding: 2 }} elevation={0}>
                <Typography variant="subtitle1" gutterBottom>
                  {getActiveLanguageData("label") || "Location"}
                </Typography>
                <OpenLayersMap height="300px" width="100%" />
              </Paper>
            );
          default:
            return null;
        }
      })()}
    </Grid>
  );
};
