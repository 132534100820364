import "@fortawesome/fontawesome-free/css/all.min.css";
import { AttachFile } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { createNextTrack } from "../../apis/inbox";
import DynamicFormPopup from "../../components/Modal/DynamicForm";
import "./inboxDetail.css";
import ReadOnlyForm from "./inboxReadOnlyform";

type InboxDetail = {
  node_name: string;
  is_completed: boolean;
  form_data: Array<FormDataItem>;
  action: Array<any>;
  formatted_created_at: string;
  created_time: string;
  uuid: string;
};

type FormDataItem = {
  input_type?: string;
  title?: string;
  label?: string;
  placeholder?: string;
  val?: string;
  attachment_url?: string;
  [key: string]: any;
};

type ActionItem = {
  label: string;
  is_completed: boolean;
};

type Props = {
  selectedInboxDetails: InboxDetail | null;
  selected: boolean;
  handleAction: (action: ActionItem) => void;
  readOnly?: boolean;
  refreshInbox?: any;
  onClickAttachment: () => void;
  category: string;
};

const EmailDetailCard: React.FC<Props> = ({
  selectedInboxDetails,
  selected,
  handleAction,
  readOnly,
  refreshInbox,
  onClickAttachment,
  category,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitLoader, setsubmitLoader] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  console.log(selectedInboxDetails);

  const fileUploadCount =
    (selectedInboxDetails?.form_data &&
      Array.isArray(selectedInboxDetails?.form_data) &&
      selectedInboxDetails?.form_data?.filter(
        (item: FormDataItem) =>
          item.element_type === "FILE_UPLOAD" ||
          item.element_type === "MULTI_FILE_UPLOAD"
      ).length) ||
    0;

  console.log(fileUploadCount);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedAction) {
      const actionItem = selectedInboxDetails?.action?.find(
        (action) => action.name === selectedAction
      );
      if (actionItem) handleAction(actionItem);
    }
  };

  const createTrackOnSubmit = async (
    formState: any,
    selectedAction: string
  ) => {
    // if (selectedProcessId) {
    setLoading(true);
    try {
      const data = await createNextTrack(
        selectedInboxDetails?.uuid ?? "",
        selectedAction,
        formState
      );
      toast.success(data);
      refreshInbox?.();
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fileArray = [{ name: "test.pdf" }, { name: "sample.pdf" }];

  console.log(selectedInboxDetails)

  return (
    <>
      {selected && selectedInboxDetails && (
        <div className="w-11/12 bg-white rounded-[8px] border-[1px] border-[#DEDEDE8C] h-[auto] m-5 overflow-hidden">
          <div className="flex justify-between items-center px-5 py-3 bg-gray-50 border-b border-[#DEDEDE8C]">
            <div className="flex items-center gap-2">
              <Avatar src="/broken-image.jpg" />
              <div className="flex flex-col">
                <h2 className="font-bold text-lg text-gray-900 m-0">
                  {selectedInboxDetails.node_name || "Node Name"}
                </h2>

                <div className="flex items-center gap-1 text-gray-400 text-xs">
                  <CalendarMonthIcon sx={{ fontSize: "12px" }} />{" "}
                  <span>{selectedInboxDetails?.formatted_created_at}</span>{"-"}
                  <span>{selectedInboxDetails?.created_time}</span>
                </div>
              </div>

              {/* {fileUploadCount > 0 && (
                <div className="ml-4 flex items-center text-sm text-gray-700">
                  <i className="fas fa-paperclip text-blue-500 mr-2"></i>
                  <span className="text-blue-600 font-medium">
                    {fileUploadCount} attachment{fileUploadCount > 1 ? "s" : ""}{" "}
                    required
                  </span>
                </div>
              )} */}
            </div>

            <div className="flex items-center gap-2">
              {!!fileUploadCount && category === "completed" && (
                <Button
                  variant="text"
                  sx={{ textTransform: "none" }}
                  startIcon={<AttachFile sx={{ transform: "rotate(45deg)" }} />}
                  onClick={onClickAttachment}
                >
                  <FormattedMessage id="attachment"></FormattedMessage>
                </Button>
              )}

              <button
                onClick={toggleCollapse}
                className="text-blue-400 hover:text-blue-800 focus:outline-none"
              >
                <i
                  className={`fas fa-chevron-${isCollapsed ? "down" : "up"}`}
                ></i>
              </button>
            </div>
          </div>

          {!isCollapsed && (
            <div className="px-5 py-5">
              <div className="m-2 flex justify-center items-center">
                <div className="w-full space-y-4 ">
                  <div className="flex flex-wrap gap-[10px]">
                    {/* {fileArray.map((item: any) => (
                      <a key={item?.id} href="">
                        <button className="bg-[#DBEDFF] rounded-[8px] p-[10px]  flex items-center space-x-2">
                          <span> {item?.name}</span>
                          <AiOutlineDownload/>
                        </button>
                      </a>
                    ))} */}
                  </div>
                  {readOnly ? (
                    <ReadOnlyForm
                      formData={selectedInboxDetails?.form_data ?? []}
                    />
                  ) : (
                    <DynamicFormPopup
                      formData={
                        readOnly ? [] : selectedInboxDetails?.form_data ?? []
                      }
                      actionList={selectedInboxDetails.action}
                      onSubmit={createTrackOnSubmit}
                      loader={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmailDetailCard;
