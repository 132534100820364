import { FC, useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import CheckBox from "../components/CheckBox";
import { elements_type } from "../constants";
import { activeLanguageData, updateTranslationData } from "../formTranslations";
import InputField from "./InputField";
import DependentPopup from "../components/dependentPopup";

interface TextFieldInterface {
  collapse: any;
  formElement: any;
  onDelete: () => void;
  onChange: (data: any, call_api?: boolean) => void;
  name?: string;
  required?: boolean;
  hidden?: boolean;
  readonly?: boolean;
  activeLanguage?: any;
  formData: any;
}

const FormCheckBox: FC<TextFieldInterface> = ({
  collapse,
  formElement,
  onChange,
  activeLanguage,
  formData,
}) => {
  const [data, setData] = useState({
    id: formElement?.id || "",
    label: formElement?.label || "",
    value: "",
    width: formElement?.width || undefined,
    element_type: elements_type.CHECKBOX,
    required: formElement?.required || false,
    hidden: false,
    readonly: false,
    show_all_stages: formElement?.show_all_stages || false,
    translate: {},
    dependentDetails: formElement?.dependentDetails || {
      parentId: "",
      condition: "",
      value: "",
    },
    enableDependent: formElement?.enableDependent || false,

  });

  // const [activeLanguage, setActiveLanguage] = useState<"en" | "ar">("en");
  const { translate } = useTranslation();

  // Update state and notify parent
  function updateData(name: string, value: any, call_api?: boolean) {
    let updatedData = updateTranslationData(
      data,
      name,
      elements_type.CHECKBOX,
      value,
      activeLanguage
    );

    console.log("updatedData", updatedData);

    setData(updatedData);
    onChange(updatedData, call_api);
  }

  function updateLocalizedField(fieldName: string, value: string) {
    console.log("updateLocalizedField", fieldName, value);

    updateData(fieldName, value, false);
  }

  const getActiveLanguageData = (fieldName: string) => {
    return activeLanguageData(data, activeLanguage, fieldName);
  };

  return (
    <>
      <>
        <div className="p-4 bg-gray-50">
          <div className="bg-white p-4 rounded-md shadow-md">
            <CheckBox
              label={
                (getActiveLanguageData("label") ||
                  translate("labelTextLabel")) + (data.required ? " *" : "")
              }
              isChecked={true}
              onChange={(e: any) => {}}
            />
          </div>
        </div>
        {collapse === formElement?.id && (
          <>
            <div className="p-4">
              <InputField
                label={translate("labelTextLabel")}
                placeholder={translate("placeHolderLabel")}
                value={getActiveLanguageData("label")}
                onChange={(value: string) =>
                  updateLocalizedField("label", value)
                }
                onBlur={(value) => updateData("label", value, true)}
              />
            </div>

            <div className="p-4">
              <DependentPopup
                data={data}
                formData={formData}
                onChange={updateData}
                activeLanguage={activeLanguage}
              />
            </div>

            <div className="flex pl-4 pr-4 pb-4 justify-between">
              <CheckBox
                label={translate("requiredErrorMessage")}
                isChecked={data.required}
                onChange={(e: any) =>
                  updateData("required", e.target.checked, true)
                }
              />
              <CheckBox
                label={translate("showAllStages")}
                isChecked={data.show_all_stages}
                onChange={(e) =>
                  updateData("show_all_stages", e.target.checked, true)
                }
              />
            </div>
          </>
        )}
      </>
    </>
  );
};

export default FormCheckBox;
