import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchAdminProcessList } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import { useParams } from "react-router";
import {
  fetchProcessDetails,
  fetchProcessVersions,
} from "../../../apis/process";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const ProcessVersionDetails = () => {
  const { processId } = useParams();

  const [processDetails, setProcessDetails] = useState<any>({});
  const [versionList, setVersionList] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchProcess = async () => {
    try {
      const res = await fetchProcessDetails(processId);
      setTotalCount(res?.count ?? 0);
      setProcessDetails(res || {});
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const fetchList = async (
    pageSize: number,
    pageNumber: number,
    search?: string
  ) => {
    try {
      const res = await fetchProcessVersions(
        processId,
        pageSize,
        pageNumber,
        search
      );
      setTotalCount(res?.count ?? 0);
      setVersionList(res || []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    fetchList(page, pageSize);
  };

  const handlePageSizeChange = (size: number) => {
    fetchList(1, size);

    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  const handleSearchChange = (value: string) => {
    setPageNumber(1);
    setPageSize(10);
    fetchList(1, 10, value);
  };

  useEffect(() => {
    fetchList(pageNumber, pageSize);
    fetchProcess();
  }, []);

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="adminThVersion" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Avatar
              src={row.icon || ""}
              sx={{ width: 42, height: 42, bgcolor: "primary.main" }}
            >
              {value?.charAt(0)}
            </Avatar>
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    { key: "created_by", label: <FormattedMessage id="adminThCreatedBy" /> },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "updated_at",
      label: <FormattedMessage id="adminThLastUpdated" />,
      render: (value: any) => formattedDate(value),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <DataTable
        columns={columns}
        data={versionList && Array.isArray(versionList) ? versionList : []}
        loading={loader}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearchChange={handleSearchChange}
        Title={
          <div className="flex items-center justify-between pb-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                <FormattedMessage id="versions"></FormattedMessage> -{" "}
                <span className="text-primary">{processDetails?.name}</span>
              </h1>
            </div>
          </div>
        }
      ></DataTable>
    </div>
  );
};

export default ProcessVersionDetails;
