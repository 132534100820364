import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import React from "react";

interface MultiSelectProps {
  name: string;
  label?: string;
  options: { value: any; label: string }[];
  onChange?: any;
  value?: any[];
  defaultValue?: Array<string>; // Array of selected values
  menuHeight?: string; // Height of the menu
}

const CustomSelect = styled(Select)(({ theme }: any) => ({
  background: "#ffffff",
  boxShadow:
    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)",
  appearance: "none",
  "& fieldset": { border: "1px solid #e5e7eb" },
}));

const MultiSelectField: React.FC<MultiSelectProps> = ({
  name,
  label,
  options,
  onChange,
  value = [],
  defaultValue = [],
  menuHeight = "100%",
}) => {
  return (
    <FormControl fullWidth>
      {label && (
        <Typography variant="subtitle1" textTransform={"capitalize"} mb={1}>
          {label}
        </Typography>
      )}
      <CustomSelect
        labelId={`${name}-label`}
        id={name}
        multiple
        onChange={onChange}
        value={value} // Controlled vs uncontrolled
        defaultValue={[]}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: menuHeight,
            },
            "& .MuiMenuItem-root": {
              height: "30px",
            },
          },
        }}
        // value={value}
        // onChange={handleChange}
        sx={{
          "&:focus": {
            outline: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5e7eb", // Change the border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5e7eb", // Remove the blue border for outlined variant
          },

          height: "39px",
          // minHeight:customHeight,
          borderRadius: "0.25rem",
          "& .MuiInputBase-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "input::placeholder": {
            fontSize: "12px",
          },
          "textarea::placeholder": {
            fontSize: "12px",
          },
        }}
        displayEmpty={true}
        renderValue={(selected: any) => {
          // return <Typography color="textSecondary">TEST</Typography>;
          if (selected?.length === 0) {
            return (
              <Typography color="textSecondary">Pls select options</Typography>
            );
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {Array.isArray(selected) &&
                selected?.map((val) => {
                  const selectedOption = options?.find(
                    (opt) => opt.value === val
                  );
                  return selectedOption ? (
                    <Chip
                      key={val}
                      label={selectedOption?.label}
                      sx={{ borderRadius: "0.50rem" }}
                    />
                  ) : null;
                })}
            </Box>
          );
        }}
      >
        {options.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            <Checkbox
              checked={Array.isArray(value) && value.includes(option?.value)}
              size="small"
            />
            <ListItemText
              primary={
                <Typography variant="caption">{option?.label}</Typography>
              }
            />
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default MultiSelectField;
