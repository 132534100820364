const Chip = ({
  value,
  type = "info",
}: {
  value: any;
  type?: "info" | "error";
}) => {
  const colors = {
    info: "bg-blue-100 text-blue-800",
    error: "bg-red-100 text-red-800",
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-semibold rounded-full ${colors[type]}`}
    >
      {value}
    </span>
  );
};

export default Chip;
