/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineDelete, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import {
  MdArrowDropDown,
  MdEditDocument,
  MdLanguage,
  MdOutlineShare,
  MdPeopleAlt,
} from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { Handle, NodeResizer, Position, useReactFlow } from "reactflow";
import * as Yup from "yup";
import {
  deleteWorkFlowNode,
  getNotificationActionList,
  getNotificationMethods,
  getNotificationUsers,
  patchWorkFlowNode,
  updateWorkFlowNode,
} from "../../apis/flowBuilder";
import DropDownNode from "../../pages/workflow V2/DropDownNode";
import MultiSelectDropdownNode from "../../pages/workflow V2/MultiSelectDropDownNode";
import { backgroundColors } from "../../utils/constants";
import DialogCustomized from "../Dialog/DialogCustomized";
import "./workflow-node.scss";
import WorkflowLanguageForm from "./WorkflowLanguageForm";
import useTranslation from "../../hooks/useTranslation";

const validationSchema = Yup.object({
  language: Yup.string().required("Language is required"),
  name: Yup.string().required("Flow name is required"),
});

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  background: "#f8f5f5",
  borderRadius: "6px",
  minHeight: 29,
  "& .MuiTabs-flexContainer": {
    // background: "linear-gradient(83.2deg, #121212 6.52%, #202020 118.43%)",
    // border: "0.5px solid #494949",
    // width: "fit-content",
    padding: "4px 4px 4px 4px",
    // borderRadius: "16px",
    // boxShadow: "0px 0px 20px 0px #00000080 inset",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  cursor: "pointer",
  textTransform: "none",
  minHeight: 30,
  height: "34px",
  borderRadius: "4px 4px 4px 4px",
  padding: "6px 8px 10px 8px",
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    minWidth: 110,
  },
  fontSize: "1rem",
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.secondary,
  "&:hover": {
    opacity: 3,
    color: theme.palette.text.primary,
  },
  "&.Mui-selected": {
    // paddingBottom: "8px",
    color: theme.palette.text.primary,
    background: "#ffffff",
    border: "1px solid #e0e0e0",
  },
  "&.Mui-focusVisible": {
    // backgroundColor: "#d1eaff",
  },
  transition: "all 0.3s ease",
}));

type NotificationPayload = {
  notification_id: string;
  edge_uuid: string;
  method_uuid: string[];
  description: string;
  staff_list: string[];
  group_uuid: string[];
  workflow_users_uuid: string[];
};

interface FormDataType {
  [key: string]: {
    name: string;
    description: string;
  };
}

const WorkFlowNode: React.FC<{
  id: string;
  data: any;
  selected?: boolean;
  position?: any;
  style?: React.CSSProperties;
  type?: string;
  process?: any;
  onUserBtnClick?: any;
  onFormBtnClick?: any;
  selectedNodeId?: string;
  setIsDrag?: any;
  groups: any;
  workflowList: any;
}> = ({
  id,
  data,
  selected,
  style,
  type,
  onUserBtnClick,
  onFormBtnClick,
  selectedNodeId,
  setIsDrag,
  groups,
  workflowList,
}) => {
  const reactFlowInstance = useReactFlow();
  const nodeDetails = reactFlowInstance.getNode(id);

  const { locale } = useIntl();
  const { notifications }: { notifications: NotificationPayload[] } = data;
  console.log(notifications);
  const [showColorPicker, setShowColorPicker] = useState(false);
  // const [nodeWidth, setNodeWidth] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [workflowTab, setworkflowTab] = useState("description");

  const [notificationList, setNotificationList] = useState([]);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [notificationMethods, setNotificationMethods] = useState([]);

  console.log(notificationList);
  console.log(notificationUsers);
  console.log(notificationMethods);
  const [isDeleting, setIsDeleting] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [isEditLangForm, setIsEditLangForm] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<FormDataType>({
    en: { name: "", description: "" },
    ar: { name: "", description: "" },
  });
  const { translate } = useTranslation();

  console.log(formData);

  useEffect(() => {
    if (!selected) return;
    if (id) {
      const getNotificationOptions = async () => {
        const data = await getNotificationActionList(id);
        setNotificationList(data);
      };
      getNotificationOptions();
    }
  }, []);

  /**
   * removed due to lots of api call
   * [id,reactFlowInstance.getEdges().length]
   *
   */
  useEffect(() => {
    // if (!selected) return;
    const getNotificationOptions = async () => {
      const data = await getNotificationActionList(id);
      setNotificationList(data);
    };
    const getUsers = async () => {
      const data = await getNotificationUsers();
      setNotificationUsers(data);
    };
    const getMethods = async () => {
      const data = await getNotificationMethods();
      setNotificationMethods(data);
    };

    if (notificationList.length === 0) {
      getNotificationOptions();
    }
    if (notificationUsers.length === 0) {
      getUsers();
    }
    if (notificationMethods.length === 0) {
      getMethods();
    }
  }, []);

  const updateNodeNotifications = async (
    notifications: NotificationPayload[],
    isUpdateAPI: boolean = true
  ) => {
    const updatedNode = {
      ...nodeDetails,
      id: id,
      data: {
        ...nodeDetails?.data,
        notifications: notifications,
      },
    };
    if (isUpdateAPI) {
      await updateWorkFlowNode(updatedNode);
    }
    reactFlowInstance.setNodes(
      reactFlowInstance
        .getNodes()
        .map((node: any) =>
          node.id === id
            ? { ...node, data: { ...data, notifications: notifications } }
            : node
        )
    );
  };

  const handleOnSaveLabel = async (value: string | number) => {
    const updatedNode = {
      id: id,
      data: {
        // label: value,
        process: data?.process,
        translations: { ...data.translations, [locale]: { name: value } },
      },
      type,
      width: 316,
      height: 51,
    };
    try {
      const save: any = await patchWorkFlowNode(updatedNode);
      console.log(save);
      reactFlowInstance.setNodes(
        reactFlowInstance.getNodes().map((node: any) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...data,
                  translations: updatedNode.data.translations,
                },
              }
            : node
        )
      );
    } catch (error: any) {
      toast.error("Ensure this field has no more than 256 characters");
    }
  };

  const handleDeleteNode = async () => {
    setIsDeleting(true);
    try {
      await deleteWorkFlowNode(id);
      reactFlowInstance.deleteElements({ nodes: [{ id }] });
      setIsDeleting(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeColor = async (color: string) => {
    const currentNodeIndex = reactFlowInstance
      .getNodes()
      .findIndex((node: any) => node.id === id);
    const restNodes = reactFlowInstance
      .getNodes()
      .filter((node: any) => node.id !== id);
    if (currentNodeIndex < 0) return;

    const currentNode = reactFlowInstance.getNodes()[currentNodeIndex];
    currentNode.style = { ...currentNode?.style, backgroundColor: color };

    reactFlowInstance.setNodes([currentNode, ...restNodes]);
    await updateWorkFlowNode(currentNode);
  };

  const handleChangeMultiSelect = (id: string, name: string, value: any) => {
    type NameKeys =
      | "staff_list"
      | "method_uuid"
      | "workflow_users_uuid"
      | "group_uuid";

    let updatedValue = notifications.map((item) =>
      item.notification_id === id
        ? {
            ...item,
            [name]: (item[name as NameKeys] ?? []).includes(value)
              ? (item[name as NameKeys] ?? []).filter(
                  (i: string) => i !== value
                )
              : [...(item[name as NameKeys] ?? []), value],
          }
        : item
    );
    updateNodeNotifications(updatedValue);
  };

  const handleAddNotification = () => {
    let temp =
      notifications && Array.isArray(notifications) ? [...notifications] : [];
    const newNotification: NotificationPayload = {
      notification_id: `${Date.now()}`,
      edge_uuid: "",
      staff_list: [],
      method_uuid: [],
      description: "",
      group_uuid: [],
      workflow_users_uuid: [],
    };

    updateNodeNotifications([...temp, newNotification]);
  };

  const handleChange = (id: string, name: string, value: any) => {
    let updatedValue = notifications.map((item) =>
      item.notification_id === id
        ? {
            ...item,
            [name]: value,
          }
        : item
    );

    updateNodeNotifications(updatedValue);
  };

  const handleDeleteNotification = (id: string) => {
    let updatedValue = notifications.filter(
      (item) => item.notification_id !== id
    );
    updateNodeNotifications(updatedValue);
  };

  const submitLanguage = async () => {
    const updatedNode = {
      id: id,
      data: {
        process: data?.process,
        translations: formData,
      },
      type,
      width: 316,
      height: 51,
    };
    try {
      await patchWorkFlowNode(updatedNode);
      reactFlowInstance.setNodes(
        reactFlowInstance.getNodes().map((node: any) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...data,
                  translations: updatedNode.data.translations,
                },
              }
            : node
        )
      );
    } catch (error: any) {
      toast.error("Ensure this field has no more than 256 characters");
    } finally {
      setSubmitLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: { language: locale, name: "", description: "" },
    validationSchema,
    onSubmit: (values) => {
      setIsEditLangForm(false);
      setFormData((state) => ({ ...state, [values.language]: values }));
    },
  });

  useEffect(() => {
    if (!formik?.values?.name) {
      formik.setFieldValue(
        "name",
        formData?.[formik.values.language as any]?.name
      );
    }
    if (!formik?.values?.description) {
      formik.setFieldValue(
        "description",
        formData?.[formik.values.language as any]?.description
      );
    }
  }, [formik.values.language, formData]);

  useEffect(() => {
    setFormData(data?.translations);
  }, [data?.translations]);

  return (
    <div>
      {isDeleting && (
        <Box
          sx={{
            position: "absolute",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            width: "95%",
            backgroundColor: "White",
          }}
        >
          Deleting...
        </Box>
      )}
      <DialogCustomized
        content={
          <WorkflowLanguageForm
            formik={formik}
            isEdit={isEditLangForm}
            setIsEdit={setIsEditLangForm}
            formData={formData}
            setFormData={setFormData}
          />
        }
        title="Title"
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        actions={
          !isEditLangForm ? (
            <Stack>
              <Button
                disabled={submitLoader}
                variant="contained"
                onClick={() => {
                  setSubmitLoader(true);
                  setDialogOpen(false);
                  setIsEditLangForm(true);
                  formik.resetForm();
                  formik.setValues({ name: "", description: "", language: "" });
                  submitLanguage();
                }}
              >
                <FormattedMessage id="done"></FormattedMessage>
              </Button>
            </Stack>
          ) : null
        }
      />
      <NodeResizer
        isVisible={false} // Show resizer only when the node is selected
        minWidth={200} // Minimum width for resizing
        maxWidth={500} // Maximum width for resizing
        minHeight={36} // Fix height
        maxHeight={36} // Fix height
        keepAspectRatio={false} // Allow free resizing
        lineClassName="custom-resizer-line" // Custom class for resizer line
        handleClassName="custom-resizer-handle" // Custom class for resizer handle
        // onResize={(event, params) => {
        //   setNodeWidth(params.width); // Update width dynamically
        // }}
        onResizeEnd={(event, params) => {
          // setNodeWidth(params.width); // Finalize the width update
        }}
      />
      {/* <Handle position={Position.Top} type="target" className="custom_handle" isConnectableStart={false}></Handle> */}
      <Handle position={Position.Left} type="target" />
      <Handle position={Position.Right} type="source" />
      <div className="flex flex-row justify-between items-center gap-4">
        <Stack direction="row" gap={1} alignItems="center" flex={1}>
          <MdOutlineShare color="blue" />
          <div
            onClick={() => {
              setIsDrag(false);
              setIsEdit(true);
            }}
            onBlur={() => {
              setIsDrag(true);
              setIsEdit(false);
            }}
            style={{ flex: 1 }}
          >
            {isEdit ? (
              <TextField
                defaultValue={data?.translations?.[locale]?.name}
                autoFocus
                variant="standard"
                size="small"
                sx={{
                  "& .MuiInputBase-input": {
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#333",
                  },
                }}
                onPointerDown={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseMove={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  reactFlowInstance.setNodes(
                    reactFlowInstance.getNodes().map((node: any) =>
                      node.id === id
                        ? {
                            ...node,
                            data: {
                              ...data,
                              translations: {
                                ...data.translations,
                                [locale]: { name: e.target.value },
                              },
                            },
                          }
                        : node
                    )
                  );
                }}
                inputProps={{ style: { height: "15px", width: "180px" } }}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    setIsEdit(false);
                    handleOnSaveLabel(data?.translations?.[locale]?.name);
                  }
                }}
                onBlur={(e) => {
                  setIsEdit(false);
                  handleOnSaveLabel(e.target.value);
                }}
              />
            ) : (
              <Tooltip title={data?.translations?.[locale]?.name ?? ""}>
                <Typography
                  variant="subtitle1"
                  width="180px"
                  color={
                    data?.translations?.[locale]?.name ? "black" : "#b7b7b7"
                  }
                  noWrap={true}
                >
                  {data?.translations?.[locale]?.name || translate("enterName")}
                </Typography>
              </Tooltip>
            )}
          </div>
        </Stack>
        <div className="flex flex-row justify-center items-center">
          <button
            className="ml-1 p-1 hover:bg-slate-300 rounded-md"
            onClick={() => {
              setDialogOpen(true);
            }}
            title={"Translation"}
          >
            <MdLanguage />
          </button>
          <button
            className="ml-1 p-1 hover:bg-slate-300 rounded-md"
            onClick={() => {
              if (
                reactFlowInstance
                  ?.getEdges()
                  // @ts-ignore
                  ?.find((dat: any) => dat.source === id)?.uuid
              ) {
                setIsMinimized((state) => !state);
              } else {
                toast.error("Please connect with nodes for add notifications");
              }
            }}
            title={isMinimized ? "Expand Details" : "Collapse Details"}
          >
            {isMinimized ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </button>
          <button
            className="ml-1 p-1 hover:bg-slate-300 rounded-md"
            onClick={() => {
              onUserBtnClick(selectedNodeId === id ? null : id);
            }}
          >
            <MdPeopleAlt />
          </button>
          <button
            className="ml-1 p-1 hover:bg-slate-300 rounded-md"
            onClick={() => {
              onFormBtnClick(
                reactFlowInstance.getNodes().find((node) => node.id === id)
              );
            }}
          >
            <MdEditDocument />
          </button>
        </div>
      </div>
      {selected && (
        <div className="overlay">
          <div className="overlayMenu">
            <button
              className="colorDropdownBtn"
              onClick={() => setShowColorPicker((prev) => !prev)}
            >
              <div
                style={{ backgroundColor: style?.backgroundColor || "white" }}
                className="activeColor"
              />
              <MdArrowDropDown color="white" />
            </button>
            <button onClick={handleDeleteNode} className="actionBtn">
              <FormattedMessage id="delete" />
            </button>
          </div>
          {showColorPicker && (
            <div className="colorPicker">
              {Object.keys(backgroundColors).map((color, index) => {
                return (
                  <button
                    key={`color-${index}`}
                    style={{ backgroundColor: backgroundColors[color] }}
                    className="colorVariant"
                    onClick={() => handleChangeColor(backgroundColors[color])}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {!isMinimized && (
        <>
          <Box height="8px"></Box>

          <Box height="8px"></Box>
          <CustomTabs
            value={workflowTab}
            onChange={(e: any, value: string) => setworkflowTab(value)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <CustomTab value="description" label="Description" />
            <CustomTab value="notification" label="Notification" />
          </CustomTabs>

          {workflowTab === "description" ? (
            <>
              <div
                style={{
                  transition: "opacity 0.3s ease",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  minHeight: "130px",
                }}
              >
                <textarea
                  style={{
                    height: "100%",
                    width: "100%",
                    outline: "none",
                  }}
                  rows={5}
                ></textarea>
              </div>
            </>
          ) : (
            <>
              {notifications &&
                Array.isArray(notifications) &&
                notifications?.map((item) => (
                  <div
                    style={{
                      transition: "opacity 0.3s ease",
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #e0e0e0",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                    }}
                  >
                    <div
                      className="flex flex-row items-center justify-between"
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      <label
                        style={{
                          display: "block",
                          color: "grey",
                          fontSize: "11px",
                          fontWeight: "600",
                        }}
                      >
                        <FormattedMessage id={"addNotification"} />
                      </label>

                      {notifications.length > 1 && (
                        <button
                          className="ml-1 p-1 hover:bg-slate-300 rounded-md"
                          onClick={() =>
                            handleDeleteNotification(item.notification_id)
                          }
                          title={"Delete"}
                        >
                          <AiOutlineDelete />
                        </button>
                      )}
                    </div>

                    <Stack spacing={1.2}>
                      <DropDownNode
                        options={
                          notificationList && Array.isArray(notificationList)
                            ? notificationList.map((item: any) => ({
                                value: item?.uuid ?? "",
                                label: item?.label ?? "",
                              }))
                            : []
                        }
                        value={item.edge_uuid}
                        id={id}
                        placeholder={
                          <FormattedMessage id="selectNotificationAction" />
                        }
                        onChange={(value) =>
                          handleChange(item.notification_id, "edge_uuid", value)
                        }
                      />

                      <MultiSelectDropdownNode
                        id={id}
                        options={
                          notificationUsers && Array.isArray(notificationUsers)
                            ? notificationUsers?.map((item: any) => ({
                                value: item?.uuid ?? "",
                                label: item?.name ?? "",
                                imgUrl: item?.profile_img,
                              }))
                            : []
                        }
                        placeholder={
                          <FormattedMessage id="selectNotificationUsers" />
                        }
                        value={item.staff_list}
                        onChange={(value) =>
                          handleChangeMultiSelect(
                            item.notification_id,
                            "staff_list",
                            value
                          )
                        }
                        search={true}
                      />
                      <MultiSelectDropdownNode
                        id={id}
                        options={
                          notificationMethods &&
                          Array.isArray(notificationMethods)
                            ? notificationMethods?.map((item: any) => ({
                                value: item?.uuid ?? "",
                                label: item?.name ?? "",
                              }))
                            : []
                        }
                        placeholder={
                          <FormattedMessage id="selectNotificationMethod" />
                        }
                        value={item.method_uuid}
                        onChange={(value) =>
                          handleChangeMultiSelect(
                            item.notification_id,
                            "method_uuid",
                            value
                          )
                        }
                      />

                      <MultiSelectDropdownNode
                        id={id}
                        options={
                          groups && Array.isArray(groups)
                            ? groups?.map((item: any) => ({
                                value: item?.id ?? "",
                                label: item?.name ?? "",
                              }))
                            : []
                        }
                        placeholder={
                          <FormattedMessage id="selectNotificationUserGroups" />
                        }
                        value={item?.group_uuid ?? []}
                        onChange={(value) =>
                          handleChangeMultiSelect(
                            item.notification_id,
                            "group_uuid",
                            value
                          )
                        }
                      />

                      <MultiSelectDropdownNode
                        id={id}
                        options={
                          workflowList && Array.isArray(workflowList)
                            ? workflowList?.map((item: any) => ({
                                value: item?.uuid ?? "",
                                label: item?.translations?.[locale]?.name ?? "",
                              }))
                            : []
                        }
                        placeholder={
                          <FormattedMessage id="selectNotificationWorkflowGroups" />
                        }
                        value={item?.workflow_users_uuid ?? []}
                        onChange={(value) =>
                          handleChangeMultiSelect(
                            item.notification_id,
                            "workflow_users_uuid",
                            value
                          )
                        }
                      />

                      {item.edge_uuid &&
                        item.staff_list.length > 0 &&
                        item.method_uuid.length > 0 && (
                          <div
                            style={{
                              transition: "opacity 0.3s ease",
                              marginTop: "10px",
                              marginBottom: "10px",
                              padding: "12px",
                              borderRadius: "8px",
                              border: "1px solid #e0e0e0",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              position: "relative",
                              minHeight: "130px",
                            }}
                          >
                            <textarea
                              style={{
                                height: "100%",
                                width: "100%",
                                outline: "none",
                              }}
                              value={item.description}
                              rows={5}
                              onChange={(e) =>
                                handleChange(
                                  item.notification_id,
                                  "description",
                                  e.target.value
                                )
                              }
                            ></textarea>
                          </div>
                        )}
                    </Stack>
                  </div>
                ))}
              <button
                onClick={handleAddNotification}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "6px",
                  border: "none",
                  backgroundColor: "#007BFF",
                  color: "white",
                  fontSize: "14px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <FormattedMessage id={"addNotificationButton"} />
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(WorkFlowNode);
