import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useIntl } from "react-intl";
import NoResults from "../NoResults";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

const DataTable = ({
  columns,
  data,
  loading = true,
  pagination = false,
  pageSize = 10,
  pageNumber = 1,
  totalCount = 0,
  onPageChange,
  onPageSizeChange,
  search = true,
  onSearchChange,
  searchPlaceholder = "Search...",
  Title = <></>,
  extraComponent = <></>,
}: {
  columns: any[];
  data: any[];
  loading: boolean;
  pagination?: boolean;
  pageSize?: number;
  pageNumber?: number;
  totalCount?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: any) => void;
  search?: boolean;
  onSearchChange?: (searchTerm: string) => void;
  searchPlaceholder?: string;
  Title?: any;
  extraComponent?: any;
}) => {
  const { locale } = useIntl();

  const [searchTerm, setSearchTerm] = useState("");

  const translated = (data: any, key: string) => {
    return data.translations?.[locale]?.[key] || data[key];
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (onPageChange) {
      onPageChange(page); // Notify the parent component about the page change
    }
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    if (onPageSizeChange) {
      onPageSizeChange(newSize); // Notify the parent component about the page size change
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearchChange && onSearchChange(e.target.value);
  };

  return (
    <div
      className="w-full shadow-md rounded-lg border relative"
      style={{ minHeight: "400px" }}
    >
      {/* Search Field */}

      <div className="p-4 border-b flex justify-between items-center">
        {Title}
        <div className="flex items-center gap-2">
          {search && (
            <TextField
              placeholder={searchPlaceholder}
              size="small"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {extraComponent}
        </div>
      </div>

      <div className="overflow-x-auto">
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 250px)" }} // Adjusted height to accommodate search field
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 260px)",
              }}
            >
              <CircularProgress sx={{ height: "10px", width: "10px" }} />
            </div>
          ) : (
            <>
              <table className="w-full text-sm text-left">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-40">
                  <tr>
                    {columns.map((col) => (
                      <th key={col.key} className="px-6 py-3">
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!loading && (!Array.isArray(data) || data.length === 0) ? (
                    <tr>
                      <td
                        colSpan={columns.length}
                        className="h-[calc(100vh_-_260px)]"
                      >
                        <NoResults />
                      </td>
                    </tr>
                  ) : (
                    data?.map((row, index) => (
                      <tr
                        key={row.uuid || index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        {columns.map((col) => (
                          <td key={col.key} className="px-6 py-4">
                            {col.render
                              ? col.render(row[col.key], row)
                              : translated(row, col.key)}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      {pagination && totalCount > 10 && (
        <div className="sticky bottom-0 bg-white border-t p-4 flex justify-between items-center">
          <FormControl variant="outlined" size="small">
            <InputLabel>Items per page</InputLabel>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              label="Items per page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            dir="ltr"
            count={totalPages}
            page={pageNumber}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
          />
        </div>
      )}
    </div>
  );
};

export default DataTable;
