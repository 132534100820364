import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { fetchBranches, fetchDeparments } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const OrganizationBranch = () => {
  const { locale } = useIntl();
  const [dataList, setDataList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchList = async (
    pageNumber: number,
    pageSize: number,
    search?: string
  ) => {
    try {
      const res = await fetchBranches(pageNumber, pageSize, search);
      setTotalCount(res.count);
      setDataList(res.results ?? []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    fetchList(page, pageSize);
  };

  const handlePageSizeChange = (size: number) => {
    fetchList(1, size);

    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  const handleSearchChange = (value: string) => {
    setPageNumber(1);
    setPageSize(10);
    fetchList(1, 10, value);
  };

  useEffect(() => {
    fetchList(pageNumber, pageSize);
  }, []);
  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="branchName" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium text-gray-700 truncate">
              {row?.translations?.[locale]}
            </span>
          </div>
        );
      },
    },
    {
      key: "branchAddress",
      label: <FormattedMessage id="branchContactDetails" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-700 truncate">
              {row?.email}
            </span>
            <span className="text-sm font-medium text-gray-500 truncate">
              {row?.contact_no}
            </span>
          </div>
        );
      },
    },
    {
      key: "branchAddress",
      label: <FormattedMessage id="branchAddress" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-700 truncate">
              {row?.addresses?.[0]?.address} , {row?.addresses?.[0]?.city}
            </span>
            <span className="text-sm font-medium text-gray-500 truncate">
              {row?.addresses?.[0]?.country}, {row?.addresses?.[0]?.postal_code}
            </span>
          </div>
        );
      },
    },

    // {
    //   key: "action",
    //   label: <FormattedMessage id="adminThAction" />,
    //   render: (_: any, row: any) => (
    //     <Link to={"/administration/organization-section/" + row.uuid}>
    //       <Button
    //         startIcon={<RemoveRedEyeOutlined />}
    //         className="rtl:gap-[10px]"
    //         sx={{ textTransform: "capitalize" }}
    //       >
    //         <FormattedMessage id="viewSection" />
    //       </Button>
    //     </Link>
    //   ),
    // },
  ];

  return (
    <div className="space-y-4 p-4">
      {/* <div className="flex items-center justify-between border-b pb-4">
        <h1 className="text-2xl font-bold text-gray-900">
          <FormattedMessage id="headingBranchManagement" />
        </h1>
      </div> */}
      <DataTable
        columns={columns}
        data={dataList && Array.isArray(dataList) ? dataList : []}
        loading={loader}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        Title={
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="headingBranchManagement" />
          </h1>
        }
        onSearchChange={handleSearchChange}
      ></DataTable>
    </div>
  );
};

export default OrganizationBranch;
