import { AddOutlined } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import useTranslation, {
  translationMessage,
} from "../../../hooks/useTranslation";
import { PreviewElements } from "../../FormPreview/FormPreview";
import CheckBox from "../components/CheckBox";
import { elements_type } from "../constants";
import {
  activeLanguageData,
  updateTranslationData,
  updateTranslationOptions,
} from "../formTranslations";
import InputField from "./InputField";
import { IntlProvider } from "react-intl";
import DependentPopup from "../components/dependentPopup";

interface RadioInterface {
  onDelete: () => void;
  onChange: (data: any, api?: boolean) => void;
  onBlur?: (value: string) => void;
  label: string;
  options?: { label: { en: string; ar: string }; value: string }[];
  activeLanguage: any;
  formElement: any;
  collapse: any;
  formData: any;
}

const Radio: FC<RadioInterface> = ({
  formElement,
  label,
  onChange,
  activeLanguage,
  collapse,
  formData,
}) => {
  const [data, setData] = useState({
    ...formElement,
    label: { en: label, ar: "" },
    element_type: elements_type.RADIOBUTTON,
    translate: formElement.translate || {
      en: { label: "", options: [{ label: "", value: "" }] },
      ar: { label: "", options: [{ label: "", value: "" }] },
    },
    dependentDetails: formElement?.dependentDetails || {
      parentId: "",
      condition: "",
      value: "",
    },
    enableDependent: formElement?.enableDependent || false
  });
  const { translate } = useTranslation();

  const addInput = () => {
    const updatedData = updateTranslationOptions(
      data,
      activeLanguage,
      "add",
      0
    );
    setData(updatedData);
  };

  function onClickDropDown(ind: number, value: string, api_call?: boolean) {
    const updatedData = updateTranslationOptions(
      data,
      activeLanguage,
      "edit",
      ind,
      value
    );
    setData(updatedData);
    onChange(updatedData, api_call);
  }

  const updateData = (label: any, value: any, apiCall: boolean = false) => {
    const updatedData = updateTranslationData(
      data,
      "label",
      elements_type.RADIOBUTTON,
      value,
      activeLanguage
    );
    setData(updatedData);
    onChange(updatedData, apiCall);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  function getCurrentLanguageData(key: string) {
    return activeLanguageData(data, activeLanguage, key);
  }
  return (
    <>
      <div className="p-4 bg-gray-50 ">
        {/* <h3 className="text-sm font-medium text-gray-700 mb-2">Preview</h3> */}
        <div className="bg-white p-4 rounded-md shadow-md">
          <IntlProvider locale={activeLanguage} messages={translationMessage}>
            <PreviewElements field={data} index={0} />
          </IntlProvider>
        </div>
      </div>
      {collapse === formElement?.id && (
        <div className="border-[1px] rounded">
          <div className="p-4">
            {/* Label Input */}
            <InputField
              label="label"
              value={getCurrentLanguageData("label")}
              placeholder={`Label (${activeLanguage.toUpperCase()})`}
              onChange={(value) => updateData("label", value)}
              onBlur={(value) => updateData("label", value, true)}
            />

            <DependentPopup
              data={data}
              formData={formData}
              onChange={updateData}
              activeLanguage={activeLanguage}
            />

            {/* Radio Button Options */}
            <div className="mt-2">
              <h5>Options for Radio Button</h5>
              {data.translate?.[activeLanguage]?.options?.map(
                (x: { label: string }, ind: number) => (
                  <div
                    className="flex justify-between mt-2 gap-x-1 items-center"
                    key={ind}
                  >
                    {/* Option Label Input */}
                    <InputField
                      label="label"
                      placeholder={`Option Label (${activeLanguage.toUpperCase()})`}
                      value={x.label}
                      onChange={(value) => onClickDropDown(ind, value, false)}
                      onBlur={(value) => onClickDropDown(ind, value, true)}
                    />
                    {/* Add New Input Button */}
                    <button
                      type="button"
                      onClick={addInput}
                      className="text-blue-700 mt-3 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                    >
                      <AddOutlined />
                      <span className="sr-only">Icon description</span>
                    </button>
                  </div>
                )
              )}
            </div>
            <div className="flex justify-between mt-4">
              <CheckBox
                label={translate("requiredErrorMessage")}
                isChecked={data?.required}
                onChange={(e: any) =>
                  updateData("required", e.target.checked, true)
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Radio;
