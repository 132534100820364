import React from "react";
import { AiFillFileText, AiOutlineDownload } from "react-icons/ai";
import useTranslation from "../../hooks/useTranslation";
import { useIntl } from "react-intl";

// components/FilePreview.tsx
interface FilePreviewProps {
  file: Record<string, string>;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file }) => {
  return (
    <div className="flex items-center gap-2 p-2 bg-gray-50 rounded-md border border-gray-200 hover:bg-gray-100 transition-colors">
      <AiFillFileText className="w-4 h-4 text-blue-500" />
      <span className="text-sm text-gray-700 flex-1 truncate">
        {file?.name}
      </span>
      <a
        href={file?.value ? encodeURI(file?.value) : "#"}
        download
        target="_blank"
        rel="noreferrer"
      >
        <AiOutlineDownload className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer" />
      </a>
    </div>
  );
};

interface MultiFilePreviewProps {
  files: any[];
}

const MultiFilePreview: React.FC<MultiFilePreviewProps> = ({ files }) => {
  return (
    <div className="space-y-2">
      {files &&
        Array.isArray(files) &&
        files?.map((file, index) => <FilePreview key={index} file={file} />)}
    </div>
  );
};

interface SignaturePreviewProps {
  imageUrl: string;
}

const SignaturePreview: React.FC<SignaturePreviewProps> = ({ imageUrl }) => {
  return (
    <div className="relative group">
      <div className="border border-gray-200 rounded-md overflow-hidden bg-white p-2">
        <img
          src={imageUrl}
          alt="Digital Signature"
          className="max-h-24 object-contain"
        />
        <div className="absolute inset-0 bg-black/0 group-hover:bg-black/5 transition-colors" />
      </div>
    </div>
  );
};

const ReadOnlyForm = ({ formData }: { formData: any }) => {
  const { locale } = useIntl();
  const renderField = (field: any) => {

    const value = field?.value?.[locale] || field.value
    switch (field.element_type) {
      case "TEXT_FIELD":
        return (
          <div className="p-2 bg-gray-50 rounded-md border border-gray-200">
            <span className="text-gray-900">{value ?? ""}</span>
          </div>
        );

      case "FILE_UPLOAD":
        return <FilePreview file={field ?? {}} />;

      case "MULTI_FILE_UPLOAD":
        return <MultiFilePreview files={value ?? []} />;

      case "DIGITAL_SIGNATURE":
        return <SignaturePreview imageUrl={field?.value} />;

      default:
        return (
          <div className="p-2 bg-gray-50 rounded-md border border-gray-200">
            <span className="text-gray-900">{value ?? ""}</span>
          </div>
        );
    }
  };

  const renderFormFields = (data: FormData) => {
    return Object.entries(data)?.map(([key, field]) => (
      <div key={key} className="mb-4 last:mb-0">
        <div className="flex items-center gap-4">
          <label className="w-1/3 text-sm font-medium text-gray-700">
            {field?.label?.[locale]?.label}
          </label>
          <div className="w-2/3">{renderField(field)}</div>
        </div>
      </div>
    ));
  };

  return <div>{renderFormFields(formData)}</div>;
};

export default ReadOnlyForm;
