import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { fetchDeparments } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import CreateDepartment from "./createDepartment";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const DepartmentAdministration = () => {
  const [dataList, setDataList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);
  const [createCatOpen, setCreateCatOpen] = useState(false);

  const fetchList = async (
    pageNumber: number,
    pageSize: number,
    search?: string
  ) => {
    try {
      const res = await fetchDeparments(pageNumber, pageSize, search);
      console.log(res);
      setTotalCount(res.count);
      setDataList(res.results ?? []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="department" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "action",
      label: <FormattedMessage id="adminThAction" />,
      render: (_: any, row: any) => (
        <Link to={"/administration/organization-section/" + row.uuid}>
          <Button
            startIcon={<RemoveRedEyeOutlined />}
            className="rtl:gap-[10px]"
            sx={{ textTransform: "capitalize" }}
          >
            <FormattedMessage id="viewSection" />
          </Button>
        </Link>
      ),
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchList(pageNumber, pageSize);
  }, []);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    fetchList(page, pageSize);
  };

  const handlePageSizeChange = (size: number) => {
    fetchList(1, size);

    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  const handleSearchChange = (value: string) => {
    setPageNumber(1);
    setPageSize(10);
    fetchList(1, 10, value);
  };

  return (
    <div className="space-y-4 p-4">
      {createCatOpen && (
        <CreateDepartment
          open={createCatOpen}
          handleClose={() => setCreateCatOpen(false)}
          onSucess={() => fetchList(pageNumber, pageSize)}
        />
      )}
      <DataTable
        columns={columns}
        data={dataList && Array.isArray(dataList) ? dataList : []}
        loading={loader}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        Title={
          <div className="flex items-center justify-between pb-4">
            <h1 className="text-2xl font-bold text-gray-900">
              <FormattedMessage id="headingDeparmentManagement" />
            </h1>
          </div>
        }
        extraComponent={
          <Link to="">
            <button
              className="flex items-center gap-2 px-4 py-2 text-white rounded-lg bg-primary"
              onClick={() => setCreateCatOpen(true)}
            >
              <BsPlus className="w-4 h-4" />
              <span>
                <FormattedMessage id="newDepartment" />
              </span>
            </button>
          </Link>
        }
        onSearchChange={handleSearchChange}
      ></DataTable>
    </div>
  );
};

export default DepartmentAdministration;
