import { useEffect, useState } from "react";
import { Stack, CircularProgress, Typography, Button } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";

const SUPPORTED_IMAGE_TYPES = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
const SUPPORTED_VIDEO_TYPES = ["mp4", "webm", "ogg"];
const SUPPORTED_AUDIO_TYPES = ["mp3", "wav", "ogg"];
const SUPPORTED_TEXT_TYPES = ["txt", "csv", "log", "json"];
const SUPPORTED_PDF = ["pdf"];

function FileViewer({ filepath, height = "100%" }: { filepath: string; height?: string }) {

  console.log(filepath)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    if (!filepath) {
      setError(true);
      setLoading(false);
      return;
    }

    const ext = filepath.split(".").pop()?.toLowerCase() || "";
    setFileType(ext);

    // Check if file is accessible
    fetch(filepath, { method: "HEAD" })
      .then((res) => {
        if (!res.ok) throw new Error("File not found");
        setLoading(false);
      })
      .catch((err) => {
        console.error("File fetch error:", err);
        setError(true);
        setLoading(false);
      });
  }, [filepath]);

  const renderDownloadButton = () => (
    <a href={filepath} download style={{ textDecoration: "none" }}>
      <Button
        variant="contained"
        startIcon={<ArrowDownward />}
        size="small"
        disableElevation
      >
        Download
      </Button>
    </a>
  );

  if (loading) {
    return (
      <Stack alignItems="center" height={height} justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack spacing={2} alignItems="center" height={height} justifyContent="center">
        <Typography variant="h6">Unable to preview this file</Typography>
        {renderDownloadButton()}
      </Stack>
    );
  }

  // 🎯 Native Render Logic
  if (SUPPORTED_PDF.includes(fileType)) {
    return (
      <iframe
        src={filepath}
        title="PDF Viewer"
        width="100%"
        height={height}
        style={{ border: "none" }}
      />
    );
  }

  if (SUPPORTED_IMAGE_TYPES.includes(fileType)) {
    return (
      <Stack
        height={height}
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={filepath}
          alt="Preview"
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      </Stack>
    );
  }

  if (SUPPORTED_VIDEO_TYPES.includes(fileType)) {
    return (
      <video
        controls
        style={{ maxWidth: "100%", maxHeight: height }}
      >
        <source src={filepath} type={`video/${fileType}`} />
        Your browser does not support the video tag.
      </video>
    );
  }

  if (SUPPORTED_AUDIO_TYPES.includes(fileType)) {
    return (
      <Stack spacing={2} alignItems="center" height={height} justifyContent="center">
        <Typography variant="body1">Audio File</Typography>
        <audio controls>
          <source src={filepath} type={`audio/${fileType}`} />
          Your browser does not support the audio element.
        </audio>
        {renderDownloadButton()}
      </Stack>
    );
  }

  if (SUPPORTED_TEXT_TYPES.includes(fileType)) {
    return (
      <iframe
        src={filepath}
        title="Text Viewer"
        width="100%"
        height={height}
        style={{ border: "1px solid #ddd", padding: "1rem", fontFamily: "monospace" }}
      />
    );
  }

  // ❌ Unsupported file type
  return (
    <Stack spacing={2} alignItems="center" height={height} justifyContent="center">
      <Typography variant="h6">No preview available</Typography>
      {renderDownloadButton()}
    </Stack>
  );
}

export default FileViewer;
