import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Avatar, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchAdminProcessList } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import { Link } from "react-router-dom";
import { fetchTableList } from "../../../apis/flowBuilder";
import { BsEye } from "react-icons/bs";
import NoResults from "../../../components/NoResults";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const CascadingParentTable = () => {
  const [dataList, setDataList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);
  const [showVersions, setShowVersions] = useState(false);
  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (search?: string) => {
    try {
      const res = await fetchTableList(search);
      setDataList(res || []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const handleSearchChange = (value: string) => {
    fetchList(value)
  }

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="tableName" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    {
      key: "type",
      label: <FormattedMessage id="type" />,
    },
    {
      key: "viewColumns",
      label: <FormattedMessage id="viewValues" />,
      render: (_: any, row: any) => (
        <Link to={"/administration/parent-tables/column-values/" + row?.id}>
          <Button
            startIcon={<RemoveRedEyeOutlined />}
            className="rtl:gap-[10px]"
            sx={{ textTransform: "capitalize" }}
            onClick={() => setShowVersions(true)}
          >
            <FormattedMessage id="view" />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <DataTable
        columns={columns}
        data={dataList && Array.isArray(dataList) ? dataList : []}
        loading={loader}
        Title={
          <div className="flex items-center justify-between pb-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                <FormattedMessage id="headingTable"></FormattedMessage>
              </h1>
            </div>
          </div>
        }
        search={true}
        onSearchChange={handleSearchChange}
      ></DataTable>
    </div>
  );
};

export default CascadingParentTable;
