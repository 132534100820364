import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchAdminProcessList } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import { Link } from "react-router-dom";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const ProcessAdministration = () => {
  const [processList, setProcessList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);
  const [showVersions, setShowVersions] = useState(false);

  const fetchList = async (
    pageNumber: number,
    pageSize: number,
    search?: string
  ) => {
    try {
      const res = await fetchAdminProcessList(pageNumber, pageSize, search);
      setTotalCount(res?.count ?? 0)
      setProcessList(res || []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    fetchList(page, pageSize);
  };

  const handlePageSizeChange = (size: number) => {
    fetchList(1, size);

    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  const handleSearchChange = (value: string) => {
    setPageNumber(1);
    setPageSize(10);
    fetchList(1, 10, value);
  };

  useEffect(() => {
    fetchList(pageNumber, pageSize);
  }, []);

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="adminThProcessName" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Avatar
              src={row.icon || ""}
              sx={{ width: 42, height: 42, bgcolor: "primary.main" }}
            >
              {value?.charAt(0)}
            </Avatar>
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    { key: "created_by", label: <FormattedMessage id="adminThCreatedBy" /> },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "updated_at",
      label: <FormattedMessage id="adminThLastUpdated" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "category",
      label: <FormattedMessage id="adminThCategory" />,
      render: (value: any) => <Chip type="info" value={value} />,
    },
    {
      key: "version",
      label: <FormattedMessage id="adminThVersion" />,
      render: (_: any, row: any) => (
        <Link to={"/administration/processes/versions/" + row?.uuid}>
          <Button
            startIcon={<RemoveRedEyeOutlined />}
            className="rtl:gap-[10px]"
            sx={{ textTransform: "capitalize" }}
          >
            <FormattedMessage id="view" />
          </Button>
        </Link>
      ),
    },
    {
      key: "action",
      label: <FormattedMessage id="adminThAction" />,
      render: (_: any, row: any) => (
        <Button
          startIcon={<RemoveRedEyeOutlined />}
          className="rtl:gap-[10px]"
          sx={{ textTransform: "capitalize" }}
          onClick={() => setShowVersions(true)}
        >
          <FormattedMessage id="view" />
        </Button>
        // <button className="text-blue-600 hover:text-blue-900 flex items-center gap-1">
        //   <BsEye className="w-4 h-4" />
        //   <span>View</span>
        // </button>
      ),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <DataTable
        columns={columns}
        data={processList && Array.isArray(processList) ? processList : []}
        loading={loader}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        Title={
          <div className="flex items-center justify-between pb-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                <FormattedMessage id="headingProcessManagement"></FormattedMessage>
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                <FormattedMessage id="subHeadingProcessManagement"></FormattedMessage>
              </p>
            </div>
          </div>
        }
        onSearchChange={handleSearchChange}
      ></DataTable>
    </div>
  );
};

export default ProcessAdministration;
