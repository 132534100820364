const enMessages = {
  menu: "Menu",
  workFlowBuilder: "Work Flow Builder",
  sidemenuDashboard: "Dashboard",
  sidemenuFlowBuilder: "Flow builder",
  sidemenuFlowBuilderV2: "Flow builder V2",
  sidemenuHeirarchy: "Heirarchy",
  sidemenuHistory: "History",
  sidemenuHome: "Home",
  sidemenuInbox: "Inbox",
  sidemenuRequests: "Requests",
  sidemenuProcessing: "Processing",
  sidemenuSettings: "Settings",
  submenuDashboards: "Dashboards",
  sidemenuAdmin: "Administration",
  submenuChartbuilder: "Chart Builder",
  submenuProcessDashboard: "Process Dashboard",
  submenuCompleted: "Completed",
  submenuNew: "New",
  submenuDepartments: "Departments",
  submenuBranch: "Branch",
  submenuOrganization: "Organization",
  aiWorkflowBuilder: "Ai WorkFlow Builder",
  defaultName: "Default Name",
  defaultDesignation: "Default Designation",
  logout: "Logout",

  downloadDocuments: "Download Documents",
  startProcess: "Start Process",
  requiredDocuments: "Required Documents",

  newAutomationFlow: "New Automation Flow",
  newCategory: "Create Category",
  drafts: "Drafts",
  recentlyViewed: "Recently Viewed",
  processName: "Process Name",
  description: "Description",
  category: "Category",
  createdBy: "Created By",
  status: "Status",
  actions: "Actions",

  addNotification: "Add Notifications to Your Systems",
  delete: "Delete",
  selectOption: "Select an option",
  selectField: "Select a Field",
  selectCondition: "Select a Condition",

  selectNotificationAction: "Select Action",
  selectNotificationType: "Select a Notification type",
  selectNotificationMethod: "Select a Notification Method",
  selectNotificationUsers: "Select Users",

  "New Node": "New Node",
  addConditions: "Add Conditions",
  addNotificationButton: "Add Notifications",
  enterValue: "Enter Value",

  previewnotavailable: "No Preview Availble for the selected Item",
  selectprefferednodetitle: "Choose your Preffered Proccess and nodes",
  selectcolor: "Select Color",

  ForwardInboxButton: "Forward",
  selectanActionInbox: "Select an action",
  actionLabelInbox: "Please select the action to be taken:",

  requiredErrorMessage: "Required",

  placeholderSearch: "Search",

  submenuAdminProcesses: "Processes",

  adminThProcessName: "Process",
  adminThRequestName: "Request Name",
  adminThRequestId: "Request Id",
  adminThCreatedBy: "Created By",
  adminThCreatedDate: "Created Date",
  adminThLastUpdated: "Last Updated",
  adminThCategory: "Category",
  adminThAction: "Action",
  adminThName: "Name",

  submenuCategories: "Categories",
  submenuUserList: "User Management",
  submenuGroups: "Groups",

  name: "Name",
  remarks: "Remarks",
  enterName: "Enter Name",
  enterDescription: "Enter Description",
  enterRemarks: "Enter Remarks",
  language: "Language",
  addLanguage: "Add Language",
  additionalLanguage: "Additional Language",
  cancel: "Cancel",
  view: "View",
  active: "Active",

  unPublishedChanges: "Unpublished Changes",
  lastVersionUpdated: "Latest version deployed",
  publish: "Publish",
  pendingChangesMessage: "You have pending changes that need to be published",

  headingProceses: "Processes",
  headingFlowBuilder: "Flow Builder",
  headingProcessDashboard: "Process Overview",

  headingProcessManagement: "Process Management",
  subHeadingProcessManagement: "View and manage all your processes",

  headingRequestManagement: "Request Management",
  subHeadingRequestManagement: "View and manage all your requests",
  headingCategoryManagement: "Category Management",
  subHeadingCategoryManagement: "View and manage all your categories",
  headingUserGroupManagement: "User Group Management",
  subHeadingUserGroupManagement: "View and manage all your user groups",

  filtersText: "Filters",
  filterDateRange: "Date Range",
  filterTo: "to",
  filterShowFavorite: "Show Favorites Only",
  filterCategory: "Filter by Category",

  filterApply: "Apply",
  filterReset: "Reset",

  formBuilderNoItems: "No form items available.",
  formBuilderElementDrop: "Your Element will drop here",

  noDataTitle: "No data available",
  placeHolderTitle: "Enter Title",
  labelTitle: "Title",

  placeHolderText: "Enter placeholder",
  labelTextPlaceholder: "Placeholder",

  placeHolderLabel: "Enter label",
  labelTextLabel: "Label",

  labelInputType: "Input Type",

  enableValidation: "Enable Validation",
  disableValidation: "Disable Validation",

  minLength: "Min Length",
  minLengthPlaceholder: "Enter minimum length",
  maxLength: "Max Length",
  maxLengthPlaceholder: "Enter maximum length",
  minValue: "Min Value",
  minValuePlaceholder: "Enter minimum value",
  maxValue: "Max Value",
  maxValuePlaceholder: "Enter maximum value",

  regularExpression: "Regular Expression",
  enterRegularExpression: "Enter regex pattern",
  showAllStages: "Show for all stages",
  defaultChecked: "Default Checked",

  optionsForDropdown: "Options for Dropdown",
  optionLabel: "Option Label",
  optionLabelPlaceholder: "Enter option label",

  AcceptFileFormat: "Accept file format",
  maxFileLabel: "Max file size in MB",
  maxFilePlaceholder: "Enter Max file size",

  startDate: "Start Date",
  endDate: "End Date",

  formText: "Form",
  previewText: "Preview",

  title: "Title",
  groupFields: "Group Fields",
  toggle: "Toggle",
  textField: "Text Field",
  checkBox: "Check Box",
  dropDown: "Drop Down",
  multiSelectDropDown: "Multi Select Drop Down",
  fileUpload: "File Upload",
  multiFileUpload: "Multi File Upload",
  date: "Date",
  dateTime: "Date Time",
  digitalSignature: "Digital Signature",

  noFieldsFormYet: "No fields have been added to the form yet.",
  startAddingFields: "Start adding fields to build your form.",

  submitButton: "Submit",
  noResults: "No results found",

  selectDefaultOption: "Select an option",

  English: "English",
  Arabic: "Arabic",

  allButton: "All",
  favoritesButton: "Favorites",

  from: "From",
  to: "To",
  clearFilter: "Clear filters",
  conditions: "Conditions",

  flowName: "Flow Name",
  requestId: "Request Id",
  attachment: "Attachment",
  notSpecified: "Not Specified",

  noDataAvailable: "No data available",
  formGroupTitle: "Form Group Title",

  radioButton: "Radio Button",

  headingDeparmentManagement: "Department Management",
  subHeadingDeparmentManagement: "View and manange all deparments",
  department: "Department",

  newDepartment: "Create Department",

  label: "Label",
  showForAllStages: "Show for all stages",
  done: "done",
  saveAsTemplate: "Save as Template",
  is_organization: "Is Organization",
  is_global: "Is Global",
  templateName: "Template Name",
  create: "Create",

  noPreview: "No preview available",

  basicInformation: "Basic Information",
  branches: "Branches",
  pricing: "Pricing",
  adminThVersion: "Version",

  versions: "Versions",
  addUsertoWorkFlow: "Add User to Workflow",

  publishWorkflow: "Publish Workflow",
  version: "version",

  designation: "Designation",

  versionName: "Version name",
  comments: "Comments",
  selectVersion: "Select an version",
  selectTemplate: "Select an template",
  selectedFile: "Selected File",
  workflow: "Workflow",
  form: "Form",
  generateWorkflow: "Generate Workflow",
  generate: "Generate",
  selectNodeToCreateForm: "Select a node to create form",

  cascadingDropdown: "Cascading Dropdown",
  selectTable: "Select Table",
  selectColumn: "Select Column",
  mapDropdownOptions: "Map Dropdown Options",

  tableName: "Table Name",
  viewDependent: "View Dependents",
  viewColumns: "View Columns",
  headingTable: "Parent Tables",

  columnName: "Column Name",
  dependentTableName: "Dependent Table Name",
  headingColumns: "Parent Table Columns",
  headingDependentTable: "Dependent Tables",

  fileName: "File name",
  max: "Max",
  supportedFiles: "Supported file formats",
  filterDepartment: "Filter by Department",
  placeholderDeparment: "Search Department",
  placeholderCategory: "Search Category",
  
  createNewProcess: "Create New Process",

  selectParentElement: "Select Parent Element",
  condition: "Select Condition",
  value: "Value",
  valuePlaceholder: "Enter value",

  editDependentDetails: "Edit",
  enableDependent: "Enable Dependent Field",
  dependantPopupTitle: "Enter Dependent Details",

  pagination: "Pagination",
  noofrows: "No of Rows Per Page",
  sorting: "Sorting",
  filter: "Filter",
  tableOptions: "Table Options",
  selectedColumns: "Selected Columns",

  type: "type",
  viewValues: "View Values",
  enableCascading: "Enable Cascading Field",

  address: "Address",
  organizationType: "Organization Type",
  industry: "Industry",

  branchName: "Branch Name",
  branchAddress: "Branch Address",
  branchContact: "Branch Contact no",
  branchManager: "Branch Manager",
  branchMail: "Email",

  planDescription: "Everything from a Business plan",
  endOn: "End on",
  currentPlan: "Current Plan",
  getItNow: "Get it Now",
  renew: "Renew",
  branchContactDetails: "Branch Contact Details",
  viewSection: "View Section",

  headingBranchManagement: "Branch Management",
  headingSectionManagement: "Section Management",
  sectionName: "Section Name",

  placeholderBranch: "Search Branch",
  placeholderSection: "Search Section",
  filterBranch: "Filter by Branch",
  filterSection: "Filter by Section",

  section: "section",
  selectNotificationUserGroups: "Select a User Group",
  selectNotificationWorkflowGroups: "Select a Workflow User"

};

export default enMessages;
